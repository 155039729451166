import { useMediaQuery } from "react-responsive";
import { FacebookIcon, InstagramIcon, WhatsAppIcon } from "./appIcons";

export const SocialNetworkButton = ({ type }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const onClick = () => {
        let link = '';
        if (type === 'facebook') {
            link = 'https://www.facebook.com/profile.php?id=100087059550369&mibextid=ZbWKwL';
        } else if (type === 'instagram') {
            link = 'https://www.instagram.com/hotelsoldemarcona?igsh=NnAyczVtM25qYmdo';
        } else if (type === 'whatsapp') {
            link = 'https://wa.me/966431764';
        } else {
            return;
        }
        window.open(link, '_blank');
    };
    return (
        <div className="SocialNetworkButton" onClick={onClick}>
            <div className="SocialNetworkIcon">
                {type === 'facebook' && <FacebookIcon fill={'#FFF'} height={isMobile ? '45px' : '54px'} width={'54px'} />}
                {type === 'whatsapp' && <WhatsAppIcon fill={'#FFF'} height={isMobile ? '50px' : '65px'} width={'65px'} />}
                {type === 'instagram' && <InstagramIcon fill={'#FFF'} height={isMobile ? '50px' : '65px'} width={'65px'} />}
            </div>
            <div className="SocialNetworkTitle">
                {type === 'facebook' && 'Facebook'}
                {type === 'whatsapp' && 'WhatsApp'}
                {type === 'instagram' && 'Instagram'}
            </div>
        </div>
    );
};