import './App.css';
import { SocialNetworkButton } from './AppComponents';
import { useMediaQuery } from 'react-responsive';

const App = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
  <div className='AppBackground'>
    <div className='AppContent'>
      <img src='./logo.png' width={isMobile ? '220px' : '350px'} style={{ marginBottom: '20px' }}></img>
      <ul className='SocialNetworkList'>
        <li><SocialNetworkButton type={'whatsapp'} /></li>
        <li><SocialNetworkButton type={'instagram'} /></li>
        <li><SocialNetworkButton type={'facebook'} /></li>
      </ul>
    </div>
    <div className='AppFooter'>© 2024 Soldemarcona. Todos los derechos reservados.</div>
  </div>
  );
};

export default App;
